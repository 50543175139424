import sendRequest from "@/libs/sendRequest";

export  const shipments_cost = {
    namespaced: true,
    state: {
        shipments_cost: [],
        shipment_cost: {}
    },
    getters: {
        getShipmentsCost: (state) => (state.shipments_cost),
        getShipmentCost: (state) => (state.shipment_cost),
    },
    mutations: {
        setShipmentsCost: (state, payload) => state.shipments_cost = payload,
        setShipmentCost: (state, payload) => state.shipment_cost = payload
    },
    actions: {
        list: async({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/costoenvio/list', 'GET', null, null, null, custom_headers);

            if(response.success && response.data) {
                commit('setShipmentsCost', response.data ?? []);
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors);
            }
        },
        view: async({ commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/costoenvio/view', 'GET', null, null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setShipmentCost', response.data ?? {});
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors)
            }
        },
        add: async({commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/costoenvio/add', 'POST', null, data, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Costo de envio guardado con éxito.'
                    }, {root: true})
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'wrong_or_empty_costo':
                            messages.push('Error: Por favor añada un costo.');
                            break;
                        case 'wrong_or_empty_maximo':
                            messages.push('Errorr: Por favor añada un costo máximo.');
                            break;
                        case 'wrong_or_empty_vigencia':
                            messages.push('Error: Por favor añada una vingencia.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            return;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Ha ocurrido un error de conexión intente más tarde.'
                    }, {root: true})
            }
        },
        update: async({ commit, rootGetters}, data) => {
            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest('administrator/costoenvio/update', 'PUT', null, data.body, data.query, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Costo de envio editado con éxito.'
                    }, {root: true})
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'wrong_or_empty_id':
                            messages.push('Error: Error interno, intente más tarde.');
                            break;
                        case 'wrong_or_empty_costo':
                            messages.push('Error: Por favor añada un costo.');
                            break;
                        case 'wrong_or_empty_maximo':
                            messages.push('Error: Por favor añada un costo máximo.');
                            break;
                        case 'wrong_or_empty_vigencia':
                            messages.push('Error: Por favor añada una vingencia.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            return;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Ha ocurrido un error de conexión intente más tarde.'
                    }, {root: true})
            }

        },
        inactive: async({ commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/costoenvio/active', 'PUT', null, null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Costo de envio eliminado con éxito.'
                    }, {root: true})
            }

            else if(!response.success && response.errors) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intenté más tarde.'
                    }, {root: true})
            }

            else if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error de conexión ha ocurrido intenté más tarde.'
                    }, {root: true})
            }
        }
    }

}