<template>
    <div class="custom_input"
        :class="$attrs.class" >

        <label :for="_id" v-if="label"
            :class="[`text-start d-block ${$attrs['data-label-class'] ? $attrs['data-label-class'] : '' }`]" 
            :style="[`${$attrs['data-label-style'] ? $attrs['data-label-style'] : ''}`]">
                 {{ label }}
        </label>
        
        <div class="position-relative">   
            <input @input="$emit('update:modelValue', $event.target.value)"
                :class="[`${$attrs['data-input-class'] ? $attrs['data-input-class'] : ''}`, 
                    (full) ? 'w-100' : '']"
                :style="[`${$attrs['data-input-style'] ? $attrs['data-input-style'] : ''}`]"
                :id="_id"
                :value="modelValue"
                :placeholder="placeholder"
                :min="min"
                :max="max"
                :maxlength="maxLength"
                :autocomplete="autocomplete"
                :minlength="minLength"
                :type="local_type"
                :disabled="disabled" />

                <img @click.stop="showPassword" v-if="local_type == 'password' && type == 'password'"
                    class="eye" 
                    :src="eye_icon"  />

                <img @click.stop="showPassword" v-if="local_type == 'text' && type == 'password'"
                    class="eye" 
                    :src="eye_slash_icon"  />

        </div>
              
    </div>
</template>

<script setup>

    import { ref } from 'vue'

    //images
    const eye_icon = require('ASSETS/icons/eye-icon')
    const eye_slash_icon = require('ASSETS/icons/eye-slash-icon')

    const props = defineProps({
        modelValue: [String, Number, FileList],
        _id:{
            type: String,
            required: false,
            default: 'test_id'
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        max:{
            type: String,
            required: false
        },
        min: {
            type: String,
            required: false
        },
        maxLength: {
            type: String,
            required: false
        },
        minLength: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false
        },
        full: {
            type: Boolean,
            required: false,
            default: true
        },
        autocomplete: {
            type: String,
            required: false,
            default: 'new-password'
        }
    })

    const emits = defineEmits(['update:modelValue'])

    const local_type = ref(null)

    //methods
    const showPassword = () => {
        local_type.value = local_type.value == 'text' ? 'password' : 'text'
        console.log('showing')
        console.log(props.type)
        console.log(local_type.value)
    }

    //life cycle

    local_type.value = props.type
</script>

<style lang="scss" scoped>
    .custom_input {
        .eye {
            width: 2rem;
            position: absolute;
            right: 1em;
            top: calc(50% - 0.8rem);
        }
    }
</style>
