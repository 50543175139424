import sendRequest from "@/libs/sendRequest";

export  const notifications = {
    namespaced: true,
    state:{
        notifications: [],
        notification: [],
        error: false
    },
    getters: {
        getNotifications: (state) => (state.notifications),
        getNotification: (state) => (state.notification),
        getError: (state) => (state.error)
    },
    mutations: {
        setNotification: (state, payload) => state.notification = payload,
        setNotifications: (state, payload) => state.notifications = payload,
        setError: (state, payload) => state.error = payload
    },
    actions: {
        list: async ({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/messagescat/list', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {
                commit('setNotifications', response.data.data ?? [])
            }

            else if(!reponse.data && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexion con la API')
            }
        },
        view: async ({ commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/messagescat/viewDetail', 'GET', null, null, id, custom_headers);

            if(response.success && response.data) {
                commit('setNotification', response.data.data ?? {})
            }

            else if(!response.success && response.errors ){
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        update: async({ commit, rootGetters, dispatch}, body) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/messagescat/update', 'PUT', null, body, null, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Notificación actualizada con éxito.', { root: true })
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                commit('setError', true)
                let messages = []

                for(let i in response.errors) {
                    if(response.errors[i].code == 'wrong_or_empty_event') {
                        messages.push('Evento requerido')
                    }

                    else if(response.errors[i].code == 'wrong_or_empty_message') {
                        messages.push('El mensaje es necesario')
                    }


                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {  
                    open: true, 
                    variant: 'danger', 
                    message: messages, 
                },{root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        add: async ({ commit, rootGetters, dispatch}, body) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/messagescat/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                //todo toast success
                dispatch('showAlert', 'Notificación enviada con éxito.', { root: true })
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                //todo toast error
                commit('setError', true)
                let messages = []

                for(let i in response.errors) {
                    if(response.errors[i].code == 'wrong_or_empty_event') {
                        messages.push('Evento requerido')
                    }

                    else if(response.errors[i].code == 'wrong_or_empty_message') {
                        messages.push('El mensaje es necesario')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {  
                        open: true, 
                        variant: 'danger', 
                        message: messages, 
                    },{root: true})
                console.log(response.errors)
            }
        },
        inactive: async({ commit, rootGetters, dispatch}, id_notification) => {
            const custom_headers = rootGetters.getHeaders

            const response = await  sendRequest('administrator/messagescat/deleteMessage', 'DELETE', null, id_notification, null, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Notificación eliminada con éxito.', { root: true })
            }

            else if(!response.success && response.errors) {
                // todo toast error
                console.log(response.errors)
                commit('setAlert', {  
                    open: true, 
                    variant: 'danger', 
                    message: ['Un error ha ocurrido intente más tarde'], 
                },{root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listClient: async({ commit, rootGetters}, client_id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('cronjobs/notifications/appNotificationHistory', 'GET', null, null, client_id, custom_headers)

            if(response.success && response.data) {
                commit('setNotifications', response.data.data ?? [])
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        sendCustomNotification: async({commit, rootGetters, dispatch}, notification_data) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('cronjobs/notifications/sendNotificationCustom', 'POST', null, notification_data, null, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Notificación enviada con éxito.', { root: true })
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
                commit('setAlert', {  
                    open: true, 
                    variant: 'danger', 
                    message: ['Un error ha ocurrido intente más tarde'], 
                },{root: true})

            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        sendNotification: async({commit, rootGetters, dispatch}, notification_data) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('cronjobs/notifications/appNotification', 'GET', null, null, notification_data, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Notificación enviada con éxito.', { root: true })
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
                commit('setAlert', {  
                    open: true, 
                    variant: 'danger', 
                    message: ['Un error ha ocurrido intente más tarde.'], 
                },{root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API.')
            }

        },
        sendMassiveNotification: async({commit, dispatch, rootGetters}, notification_data) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('cronjobs/notifications/sendNotificationMassive', 'POST', null, notification_data, null, custom_headers)

            if(response.success && response.data) {
                commit('setError', false)
                dispatch('showAlert', 'Notificación enviada con éxito.', { root: true })
            } 

            else if(!response.success && response.errors) {
                let messages = []
                for(let i in response.errors) {
                    console.log('i:', i)
                    console.log(response.errors[i].code)
                    if(response.errors[i].code == 'wrong_or_empty_clave_clients') {
                        messages.push('No ha seleccionado ningun usuario.')
                    }

                    else if(response.errors[i].code == 'wrong_or_empty_message') {
                        messages.push('El mensaje es requerido.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {  
                    open: true, 
                    variant: 'danger', 
                    message: messages, 
                },{root: true})
                commit('setError', true)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        }
    }

}