import _Store from '@/store'
import _Router from '@/router'
import CONFIG from '../page.config'

export default async function sendRequest(url = null, method = 'GET', params = null, body = null, query = null, custom_headers = null) {
    // console.log(custom_headers, 'custom_headers')
    // INIT
    let send_url = `${CONFIG.server[CONFIG.env].api}/${url}`
    let headers = (custom_headers == null) ? CONFIG.server[CONFIG.env].headers : custom_headers

    let send_data = {
        method: method,
        headers: headers,
    }

    // PARAMS - BODY
    if(query !== null) {
        let formQuery = []

        for(var property in query) {
            var encodedKey = encodeURIComponent(property)
            var encodedValue = encodeURIComponent(query[property])
            // console.log(encodedKey + "=" + encodedValue)
            formQuery.push(encodedKey + "=" + encodedValue)
        }

        formQuery = formQuery.join("&")

        if(method == 'GET') {
            send_url = `${send_url}?${formQuery}`
        }
        else {
            send_url = `${send_url}?${formQuery}`
            if(body !== null) {
                send_data.body = JSON.stringify(body)
            }
        }
    }
    else {
        if(params !== null) {
            send_data.params = JSON.stringify(params)
        }

        else if(body !== null && headers['Content-Type']) {
            send_data.body = JSON.stringify(body)
        }

        else {
            send_data.body = body
        }

    }
    

    // CONSOLES LOG
    //console.log('SEND URL:', send_url)
    //console.log('SEND DATA:', send_data)

    // FETCH
    let resp = fetch(send_url, send_data).then( (res) => {

        return res.json()
        
    })
    .catch(async error => {
        console.log('ERROR:', error)
    })
    .then(async response => {
        
        if(response.http == 200 && response.data !== null) {
            // console.log('DATA:', response.data)

            return {
                data: response.data,
                success: true
            }
        }
        else if(response.http == 202) {
            // console.log('ERRORS:', response.errors)
            
            return {
                errors: response.errors,
                success: false
            }
        }
        else if(response.http == 404) {
            // console.log('404:', response.errors)

            return {
                error: [{
                    code: 404,
                    message: 'Url not found'
                }],
                success: false
            }
        }
        if(response.http == 401 && response.errors !== null) {
            localStorage.clear()
            _Router.push({ name: 'login' })

            return {
                data: [],
                success: true
            }
        }
        else {
            return {
                data: [],
                success: false
            }
        }

    })

    return resp
}