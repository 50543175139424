export default [
    {
        path: '/catalogues/presale-assesors',
        name: 'presale',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Presales.vue')
    },
    {
        path: '/catalogues/assortment',
        name: 'assortment',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Assortment.vue')
    },
    {
        path: '/catalogues/products',
        name: 'products',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Products.vue')
    },
    {
        path: '/catalogues/categories',
        name: 'categories',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Categories.vue')
    },
    {
        path: '/catalogues/families',
        name: 'families',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Families.vue')
    },
    {
        path: '/catalogues/raw-products',
        name: 'raw-products',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/RawProducts.vue')
    },
    {
        path: '/catalogues/notifications',
        name: 'notifications',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Notifications.vue')
    },
    {
        path: '/catalogues/shipment-cost',
        name: 'shipment-cost',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/ShipmentCost.vue')
    },
    {
        path: '/catalogues/banners',
        name: 'banners',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/catalogues/Banners.vue')
    }
]