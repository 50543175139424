<template>
    <div>
        <GChart 
            :data="data" 
            :options="options" 
            :type="type"
            :settings="settings"
            />
    </div>
</template>


<script>

import { GChart } from 'vue-google-charts';


export default {
    name: 'charts',
    components: {
        GChart
    },
    props: {
        data: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: () => {}
        },
        settings: {
            type: Object,
            default: () => {}
        }
    },
    setup() {

    }
}
</script>