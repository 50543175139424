<template>
  <div id="datatable">
    <vue3-datatable-component
      :fields="fields"
      :data = data
      searchFilter="true"
      pagination = "true"
      sort="true"
    />
  </div>
</template>

<script>

import Vue3DatatableComponent from "vue3-datatable-component";

export default {
  name: 'DataTable',
  components: {
    Vue3DatatableComponent,
  },
  setup() {
    //An array of values for the data
    const data = [
      {
        ID: "01",
        Name: "Abiola Esther",
        Course: "Computer Science",
        Gender: "Female",
        Age: "17",
      }
    ]
    
    const fields = ["ID", "Name", "Course", "Gender", "Age"];
    return { data, fields };
  },
};
</script>