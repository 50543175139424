import sendRequest from "@/libs/sendRequest";

export  const banners = {
    namespaced: true,
    state: {
        banners: [],
        banner: {}
    },
    getters: {
        getBanners: (state) => (state.banners),
        getBanner: (state) => (state.banner),
    },
    mutations: {
        setBanners: (state, payload) => state.banners = payload,
        setBanner: (state, payload) => state.banner = payload
    },
    actions: {
        list: async({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/banners/list', 'GET', null, null, null, custom_headers);

            if(response.success && response.data) {
                commit('setBanners', response.data ?? []);
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors);
            }
        },
        view: async({ commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/banners/view', 'GET', null, null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setBanner', response.data ?? {});
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors)
            }
        },
        add: async({commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;


            const response = await sendRequest('administrator/banners/add', 'POST', null, data, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Banner guardado con éxito.'
                    }, {root: true})
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'wrong_or_empty_posicion':
                            messages.push('Error: Por favor añada una posición.');
                            break;
                        case 'wrong_or_empty_texto':
                            messages.push('Erorr: Por favor añada un texto.');
                            break;
                        case 'wrong_or_empty_imagen':
                            messages.push('Error: Por favor añada una imagen.');
                            break;
                        case 'wrong_or_empty_accion':
                            messages.push('Error: Por favor añada una acción.');
                            break;
                        case 'wrong_or_empty_tipo_accion':
                            messages.push('Error: Por favor añada un tipo de acción.');
                            break;
                        case 'wrong_or_empty_categoria_id':
                            messages.push('Error: Por favor añada una categoria');
                            break;
                        case 'wrong_or_empty_fecha_fin':
                            messages.push('Error: Por favor añada una fecha');
                            break;
                        case 'wrong_or_empty_orden':
                            messages.push('Error: Por favor añada un orden');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            return;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Ha ocurrido un error de conexión intente más tarde.'
                    }, {root: true})
            }
        },
        update: async({ commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/banners/update', 'PUT', null, data, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Banner editado con éxito.'
                    }, {root: true})
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'wrong_or_empty_id':
                            messages.push('Un error interno ha ocurrido intente más tarde');
                            break;
                        case 'wrong_or_empty_posicion':
                            messages.push('Error: Por favor añada una posición.');
                            break;
                        case 'wrong_or_empty_texto':
                            messages.push('Erorr: Por favor añada un texto.');
                            break;
                        case 'wrong_or_empty_imagen':
                            messages.push('Error: Por favor añada una imagen.');
                            break;
                        case 'wrong_or_empty_accion':
                            messages.push('Error: Por favor añada una acción.');
                            break;
                        case 'wrong_or_empty_tipo_accion':
                            messages.push('Error: Por favor añada un tipo de acción.');
                            break;
                        case 'wrong_or_empty_categoria_id':
                            messages.push('Error: Por favor añada una categoria');
                            break;
                        case 'wrong_or_empty_fecha_fin':
                            messages.push('Error: Por favor añada una fecha');
                            break;
                        case 'wrong_or_empty_orden':
                            messages.push('Error: Por favor añada un orden');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde')
                            return;
                    }
                }

                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: messages
                    }, {root: true})
            }

            else if(response.success && !response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Ha ocurrido un error de conexión intente más tarde.'
                    }, {root: true})
            }

        },
        inactive: async({ commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/banners/active', 'PUT', null, null, { id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'success', 
                        message: 'Banner eliminado con éxito.'
                    }, {root: true})
            }

            else if(!response.success && response.errors) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intenté más tarde.'
                    }, {root: true})
            }

            else if(response.success && response.data) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error de conexión ha ocurrido intenté más tarde.'
                    }, {root: true})
            }
        }
    }

}