import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import Login from 'ROUTER/modules/login.js'
import Catalogues from 'ROUTER/modules/catalogues.js'
import Reports from 'ROUTER/modules/reports.js'
import Clients from 'ROUTER/modules/clients.js'
import CustomerService from 'ROUTER/modules/customerservice.js'

const routes = [
    ...Login,
    ...Catalogues,
    ...Reports,
    ...Clients,
    ...CustomerService
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const session = store.getters['getSession']
  
    if(session == null && to.name !== 'login') {
        next({
            name: 'login'
        })
    }

    else if(session !== null && to.name == 'login') {
        console.log(session);
        console.log('here')
        // next(false)
        next({
            name: 'sales'
        })
    }

    else {
        next()
    }

})

export default router
