import sendRequest from "@/libs/sendRequest";

export  const products = {
    namespaced: true,
    state:{
        product: [],
        products: [],
        total_categories: []
    },
    getters: {
        getProduct: (state) => (state.product),
        getProducts: (state) => (state.products),
        getTotalCategories: (state) => (state.total_categories)
    },
    mutations: {
        setProduct: (state, payload) => state.product = payload,
        setProducts: (state, payload) => state.products = payload,
        setOperations: (state, payload) => state.operations = payload,
        setTotalCategories: (state, payload) => state.total_categories = payload
    },
    actions: {
        list: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/select', 'GET', null, null, null, custom_headers);
            
            if(response.success) {
                commit('setProducts', response.data.productos)
            }
        },
        view: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/view', 'GET', null, null, query, custom_headers);
            
            if(response.success) {
                commit('setProduct', response.data.productos)
            }
        },
        update: async({commit, dispatch, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/update', 'PUT', null, body, null, custom_headers);
            
            if(response.success) {
                dispatch('showAlert', 'Producto editado con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }
        },
        resources: async ({ commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/resources', 'GET', null, null, query, custom_headers)

            if(response.success) {

            }
        },
        productClient: async ({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/productClient', 'GET', null, null, query, custom_headers );

            if(response.success) {

            }
        },
        promoProductClient: async ({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/promoProductClient', 'GET', null, null, query, custom_headers );

            if(response.success) {
                
            }
        },
        saveFilter: async ({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/saveFilter', 'POST', null, body, null, custom_headers );

            if(response.success) {
                
            }
        },
        categorie: async ({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/categorie', 'GET', null, null, query, custom_headers );

            if(response.success) {
                for(let i in response.data.productos) {
                    response.data.productos[i].imagen = `https://api.wiks-services.online/administrator/products/resources?id=${response.data.productos[i].ProductoClave}&v=${Date.now()}`
                }

                commit('setProducts', response.data.productos)
                console.log(response.data.productos)
            }
        },
        totalCategories: async ({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/products/totalCategorieProduct', 'GET', null, null, query, custom_headers );

            if(response.success) {
                commit('setTotalCategories', response.data.Total.Total ? response.data.Total.Total : 0 )
            }
        }
    }

}