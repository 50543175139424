<template>
    <section>
        <div class="scroll table-responsive pb-1 m-0 mb-2">
            <table class="table p-0 m-0">
                <thead>
                    <tr>
                        <template v-for="(header, index_header) in get_headers" >
                            <th :class="`bg-${color_headers} text-${color_headers_text} border-0 text-center p-1 table_item`" 
                                scope="col" 
                                v-if="allow_key_headers.includes(header)"
                                :key="`${index_header}_header`"
                                :width="findWidth(header)">
                                
                                    {{ findLabel(header) }} 
                            </th>

                        </template>
                        

                        <!-- Missing Headers -->
                        <th :class="`bg-${color_headers} text-${color_headers_text} border-${border_headers} text-center p-3`" 
                            scope="col" 
                            v-for="(m_header, index_m_header) in missing_headers" 
                            :key="`${index_m_header}_missing_header`"
                            :width="findWidth(m_header)">

                                {{ m_header }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(data_item, data_index) in data" :key="data_index" 
                        :class="[`text-${color_body_text} text-center p-1`, 
                            ((data_index % 2) && two_colors) ? `bg-${color_body}-dark` : `bg-${color_body}`]">

                        <template v-for="(item, index_item) in data_item">
                            <td  v-if="allow_key_headers.includes(index_item)"
                                :key="`${index_item}_item`"
                                :class="`border-${border_body}`" >

                                <slot :name="index_item" 
                                    v-bind:value="data_item[index_item]" 
                                    v-bind:item="data_item">
                                            {{ item }}
                                </slot>
                            </td>
                        </template>

                        <td v-for="(m_header, index_m_header) in missing_headers" 
                            :key="`${index_m_header}_missing_item`"
                            :class="`border-${border_body}`">

                            <slot :name="m_header" 
                                v-bind:item="data_item">
                                    {{ m_header }}
                            </slot>
                        </td>

                    </tr>
                </tbody>
                
                <thead>
                    <tr>
                        <template v-for="(header, index_header) in get_headers" >
                            <th :class="`bg-${color_headers} text-${color_headers_text} border-0 text-center p-1 table_item`" 
                                scope="col" 
                                v-if="allow_key_headers.includes(header)"
                                :key="`${index_header}_header`"
                                :width="findWidth(header)">
                                
                                    {{ findLabel(header) }} 
                            </th>

                        </template>
                        
                    </tr>
                </thead>
            </table>

            <div class="col-12 text-center border-secondary py-3" v-if="no_records">
                <span>No se encontraron registros</span>
            </div>
        </div>

        <div class="row" v-if="data.length > 0 && !pagination_off">
            <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-outline-light btn-pill py-1 px-4 mx-1">
                    <img :src="icon_arrow_left" class="">
                </button>

                <button class="btn btn-light btn-pill py-1 px-3 mx-1">
                    <span class="ad--description-sm">1</span>
                </button>

                <button class="btn btn-light btn-pill py-1 px-4 mx-1">
                    <img :src="icon_arrow_right" class="">
                </button>
            </div>
        </div>
    </section>
</template>

<script setup> 

    import { defineProps, computed } from 'vue'

    const icon_arrow_left = require('ASSETS/icons/arrow_left') 
    const icon_arrow_right = require('ASSETS/icons/arrow_right')

    
    //props
    const props = defineProps({
        allow_headers: {
            type: Array,
            required: true,
            default: [],
        },
        data: {
            type: Array,
            required: true,
            default: [],
        },
        color_headers: {
            type: String,
            required: false,
            default: 'secondary',
        },
        color_headers_text: {
            type: String,
            required: false,
            default: 'white',
        },
        border_headers: {
            type: String,
            required: false,
            default: 'secondary',
        },
        color_body: {
            type: String,
            required: false,
            default: 'dark'
        },
        color_body_text: {
            type: String,
            required: false,
            default: 'white',
        },
        border_body: {
            type: String,
            required: false,
            default: 'secondary',
        },
        two_colors: {
            type: Boolean,
            required: false,
            default: false
        },
        pagination_off: {
            type: Boolean,
            required: false,
            default: false  
        },
        no_records: {
            type: Boolean,
            required: false,
            default: false
        }
    })

    //computed
    const get_headers = computed( () => {
        if(props.data !== null && props.data.length > 0) {
            // this.$logEnv(Object.keys(this.data[0]))
        return Object.keys(props.data[0])
        }

        return []
    })

    const allow_key_headers = computed(() => {
        let allow = []

        for(let i in props.allow_headers) {
            if(_.isObject(props.allow_headers[i]) && !_.isNull(props.allow_headers[i].key) ) {
                allow.push(props.allow_headers[i].key)
            }

            else {
                allow.push(props.allow_headers[i])   
            }
        }

        return allow
    })

    const missing_headers = computed(() => {
        let difference = allow_key_headers.value.filter(x => !get_headers.value.includes(x));
        return difference
    })

    //methods
    const findLabel = (key) => {
        for(let i in props.allow_headers) {

            if(_.isObject(props.allow_headers[i]) && !_.isNull(props.allow_headers[i].key)) {
                if(props.allow_headers[i].key == key) {
                    return props.allow_headers[i].label    
                }
            }

            else {
                return props.allow_headers[i]
            }
        }
    }

    const findWidth = (key) => {
        for(let i in props.allow_headers) {
            if(_.isObject(props.allow_headers[i]) && !_.isNull(props.allow_headers[i].key)) {

                if(props.allow_headers[i].key == key) {
                    let w = props.allow_headers[i].width
                    return ((w !== null) ? `${w}%` : '')
                }
            }

            else {
                let w = props.allow_headers[i]
                return ((w !== null) ? w : '')
            }
        }
    }

</script>

<style lang="scss">
    @import '../assets/scss/colors.scss';
 
    .scroll::-webkit-scrollbar {
        height: 10px;
    }

    .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
        border-radius: 10px;
    }

    .scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.5);
    }
</style>