export default [
    {
        path: '/customerservice/routes',
        name: 'routes',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/customerservice/Routes.vue')
    },
    {
        path: '/customerservice/routes',
        name: 'chat',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/customerservice/Chat.vue')
    }
]