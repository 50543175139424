export default [
    {
        path: '/',
        name: 'login',
        meta: {
            layout: 'default'
        },
        component: () => import('VIEWS/Login.vue')
    }

]