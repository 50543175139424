<template>
    <div>
        <GMapMap 
            :center="{ lat: latitude, lng: longitude }" 
            :zoom="zoom" 
            style="width: 100%; height: 320px"
            :options="{
                 mapTypeControl: false,
                 streetViewControl: true,
                 fullscreenControl: true,
            }"
                >
            <GMapMarker 
                :key="index" 
                v-for="(m, index) in data" 
                :position="m.position"
                :title="m.name"
                :clickable="true" 
                :draggable="true"
                @click="openMarker(m.id)"
               
            >
                <GMapInfoWindow
                    :opened="openedMarkerId === m.id"
                    :options=" {
                        width: '400px',
                        maxHeight: 320,
                    }"
                >
                    <div style="width: 200px;" class="text-start">
                        Razón Social: <b>{{ m?.name }}</b>
                        <br>
                        Cliente Clave: <b>{{ m?.code }}</b>
                        <br>
                        Operaciones: <b>{{ m?.operations }}</b>
                        <br>
                        Venta: <b style="font-size:24px">${{ m?.sales }}</b>
                    </div>
                </GMapInfoWindow>
            </GMapMarker>
        </GMapMap>
    </div>
</template>

<script>

import { ref } from 'vue'
export default {
    name: 'maps',
    props: {
        latitude: {
            type: Number,
            required: true
        },
        longitude: {
            type: Number,
            required: true
        },
        zoom: {
            type: Number,
            default: () => 14
        },
        data: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    setup() {

        const openedMarkerId = ref(null)

        const openMarker = (id) => {
            openedMarkerId.value = id
        }


        return {
            openedMarkerId,
            openMarker
        }

    }
}
</script>