import sendRequest from "@/libs/sendRequest";

export  const operations = {
    namespaced: true,
    state:{
        operation: [],
        operations: [],
        ticket: false,
        detail:[],
        customer: [],
        total_operations: null
    },
    getters: {
        getOperation: (state) => (state.operation),
        getOperations: (state) => (state.operations),
        getDetail: (state) => (state.detail),
        getCustomer: (state) => (state.customer),
        getTotalOperations: (state) => (state.total_operations),
        getTicket: (state) => (state.ticket)
    },
    mutations: {
        setOperation: (state, payload) => state.operation = payload,
        setOperations: (state, payload) => state.operations = payload,
        setDetail: (state, payload) => state.detail = payload,
        setCustomer: (state, payload) => state.customer = payload,
        setTotalOperations: (state, payload) => state.total_operations = payload,
        setTicket: (state, payload) => state.ticket = payload
    },
    actions: {
        cardPay: async({commit, rootGetters }) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/operations/card_pay', 'GET', null, null, null, custom_headers);

            if(response.success) {
                commit('setOperations', response.data.ordenes)
            }
        },
        // list: async({commit, rootGetters }, page) => {
        //     const custom_headers = rootGetters.getHeaders;

        //     const response = await sendRequest(`administrator/operations/select?pagina=${page}`, 'GET', null, null, null, custom_headers);

        //     if(response.success) {
        //         commit('setOperations', response.data.ordenes)
        //     }
        // },
        list: async({commit, rootGetters }, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest(`administrator/operations/select`, 'GET', null, null, query, custom_headers);

            if(response.success) {
                commit('setOperations', response.data.ordenes || [])
            }
        },
        view: async({commit, rootGetters }, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/operations/view', 'GET', null, null, query, custom_headers);

            if(response.success) {
                commit('setOperation', response.data)
            }
        },
        detail: async({commit, rootGetters }, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/operations/detail', 'GET', null, null, query, custom_headers);

            if(response.success) {
                commit('setDetail', response.data.detalle)
            }
        },
        ticket: async({commit, rootGetters }, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/operations/ticket', 'GET', null, null, { order_id: id}, custom_headers);
            console.log(response)
            if(response.success) {
                commit('setTicket', response.data.send )
            }
        },
        customer: async({commit, rootGetters }, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/operations/customer', 'GET', null, null, query, custom_headers);

            if(response.success) {
                commit('setCustomer', response.detalle)
            }
        },
        total: async({commit, rootGetters }) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/operations/total', 'GET', null, null, null, custom_headers);

            if(response.success) {
                commit('setTotalOperations', response.data.total.Total)
            }
        }
    }

}