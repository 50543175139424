import { createStore } from 'vuex'
import AES from '@/libs/AES'
import sendRequest from '@/libs/sendRequest'
import router from '@/router'

import { advisers } from './modules/advisers';
import { families } from './modules/families';
import { categories } from './modules/categories';
import { clients } from './modules/clients';
import { operations } from './modules/operations';
import { products } from './modules/products';
import { registries } from './modules/registries';
import { notifications } from './modules/notifications';
import { reports } from './modules/reports';
import { shipments_cost } from './modules/shipmentsCost';
import { banners } from './modules/banners';
import CONFIG from '../../src/page.config'

export default createStore({
	state: {
		alert: {
            open: false,
            message: 'Error',
            variant: 'danger',
            color_text: 'white',
            timeout: null,
        },
		state: null
	},
	getters: {
		getAlert: (state) => state.alert,
		getSection: (state) => state.section,
		getSession: (state) => {
			
			
			let session = JSON.parse(sessionStorage.getItem('wiks@session')) || null
			state.session = session;
			return state.session
		},
		getHeaders (state) {
			//session: JSON.parse(sessionStorage.getItem('live@session')).token_session
			return {
			  ...CONFIG.server[CONFIG.env].headers
			}
		}
	},
	mutations: {
		setAlert: (state, new_alert) => {
			state.alert.open = new_alert.open
			state.alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
			state.alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
			state.alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
			state.alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 3000

			if (state.alert.timeout !== null) {
				setTimeout(() => {
					state.alert.open = false
				}, state.alert.timeout)
			}
		},
		setSection: (state, payload) => (state.section = payload),
		setSession: (state, payload) => {
			let encrypt = AES.encrypt(JSON.stringify(payload))
			console.group(encrypt)
			state.session = payload
			sessionStorage.setItem('wiks@session', JSON.stringify(payload))
			sessionStorage.setItem('encrypted', encrypt)
		}
	},
	actions: {
		showAlert: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message
            })
        },
		login: async ({ commit, dispatch}, body) => {

			let response = await sendRequest('administrator/registries/login', 'POST', null, body, null)

			if(response.success && response.data.registro_datos) {
				commit('setSession', response.data.registro_datos)
				dispatch('showAlert', 'Bienvenido')
				router.push({ name: 'sales'})
			}

			else {
				commit('setAlert', { open: true, variant: 'danger', message: 'Usuario o Contraseña incorrectos'})
				console.log(response.errors)
			}
		}
	},
	modules: {
		advisers,
		families,
		categories,
		clients,
		operations,
		products,
		registries,
		notifications,
		reports,
		shipments_cost,
		banners
	}
})
