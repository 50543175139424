import sendRequest from "@/libs/sendRequest";

export  const families = {
    namespaced: true,
    state:{
        family: [],
        families: [],
        error: null

    },
    getters: {
        getFamily: (state) => (state.family),
        getFamilies: (state) => (state.families),
        getError: (state) => (state.error)
    },
    mutations: {
        setFamily: (state, payload) => state.family = payload,
        setFamilies: (state, payload) => state.families = payload,
        setError: (state, payload) => state.error = payload
    },
    actions: {
        list: async({commit, rootGetters}, query) =>{

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/family/select', 'GET', null, null, null, custom_headers)

            if(response.success) {
                commit('setFamilies', response.data.family)
            }
        },
        view: async({commit, rootGetters}, query) =>{

            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/family/view', 'GET', null, null, query, custom_headers)
    
            if(response.success && response.data) {
                commit('setFamily', response.data.family)
                commit('setError', false)
            }

            if(!response.success && response.errors) {

                let messages = []
                
                for(let error in response.errors) {
                    if(response.errors[error].code) {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }

                }
                commit('setError', true)
                commit('setAlert', {  
                        open: true, 
                        variant: 'danger', 
                        message: messages, 
                    },{root: true})

            }
        },
        add: async({commit, dispatch, rootGetters}, body) =>{
    
            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/family/save', 'POST', null, body, null, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Categoria añadida con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }
        },
        update: async({commit, dispatch, rootGetters}, body) =>{
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/family/update', 'POST', null, body, null, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Familia editada con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }
        },
        inactive: async({commit,dispatch, rootGetters}, body) =>{
    
            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/family/active', 'PUT', null, body, null, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Familia eliminada con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }
        }
    }

}