<template>
    <div id="template-default" class="p-0">
        <slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'template-default',
		data() {
			return {
                
			}
		}
	}
</script>


<style lang="scss" scoped>
    #template-default {
    	
    }
</style>