import sendRequest from "@/libs/sendRequest";

export  const advisers = {
    namespaced: true,
    state:{
        adviser: [],
        advisers: [],

    },
    getters: {
        getAdviser: (state) => (state.adviser),
        getAdvisers: (state) => (state.advisers)
    },
    mutations: {
        setAdviser: (state, payload) => state.adviser = payload,
        setAdvisers: (state, payload) => state.advisers = payload,
    },
    actions: {
        list: async({commit, rootGetters}) =>{

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/adviser/listAdviser', 'GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                commit('setAdvisers', response.data.asesores)
            }

            else if(!response.succes && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },        
        listAssortment: async({commit, rootGetters}) =>{

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/adviser/listAssortment', 'GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                commit('setAdvisers', response.data.asesores)
            }

            else if(!response.succes && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        view: async({commit, rootGetters}, query) =>{

            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/adviser/view', 'GET', null, null, query, custom_headers)
    
            if(response.success) {
                commit('setAdviser', response.data.asesor_datos)
            }
        },
        add: async({commit, rootGetters, dispatch}, body) =>{
    
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/adviser/save', 'POST', null, body, null, custom_headers)
            console.log(response)
            if(response.success && response.data) {
                dispatch('showAlert', 'Asesor añadido con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }
        },
        update: async({commit,dispatch, rootGetters}, body) =>{
    
            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/adviser/update', 'PUT', null, body, null, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Asesor editado con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }
        },
        inactive: async({commit, dispatch, rootGetters}, query) =>{
    
            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/adviser/deleteAdviser', 'GET', null, null, query, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Asesor eliminado con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }

        }
    }

}