export default [
    {
        path: '/clients/registered',
        name: 'registered',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/clients/RegisteredClients.vue')
    },
    {
        path: '/clients/unregistered',
        name: 'unregistered',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/clients/UnregisteredClients.vue')
    },
    {
        path: '/clients/movements',
        name: 'movements',
        meta: {
            layout: 'session'
        },
        component: () => import('VIEWS/clients/Movements.vue')
    }
]