import sendRequest from "@/libs/sendRequest";

export  const registries = {
    namespaced: true,
    state:{
        registries: [],
        registry: []
    },
    getters: {
        getRegistries: (state) => (state.registries),
        getRegistry: (state) => (state.registry)
    },
    mutations: {
        setRegistry: (state, payload) => state.registry = payload,
        setRegistries: (state, payload) => state.regisitries = payload
    },
    actions: {
        list: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/registries/list', 'GET', null, null, null, custom_headers );

            if(response.success) {

            }
        },
        view: async ({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/registries/view', 'GET', null, null, query, custom_headers);

            if(response.success) {

            }
        }
    }

}