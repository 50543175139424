<template>
    <section id="header" class="bg-lightiest-gray row px-0 mx-0" style="height: 100%">

        <div class="col-12 col-sm-9 col-lg-10 col-xxl-11 d-flex  align-items-center">
            <div class="row justify-content-center ms-0 ms-sm-5 pt-2 pt-sm-0" style="width: 300px; height: 60px;">

                <div class="col-4 px-0 mx-0 col-sm-2 d-flex align-items-center">
                    <img class="" width="40" :src="avatar" alt="user picture">
                </div>

                <div class="col-8 px-0 mx-0 text-start">
                    <span class="d-block" style="font-size: 20px; font-weight: bolder;">
                        {{ session.Nombre }} {{ session.Apellido}}
                    </span>
                    <span class="d-block">
                        {{ session.Username}}
                    </span>
                    <span>
                        Bienvenido
                    </span>
                </div>

            </div>
        </div>

        <div class="col-12  col-sm-3 col-lg-2 col-xxl-1 d-flex justify-content-evenly">
            <div class="d-flex position-relative">
                <img width="30" :src="bell" alt="">
                <span class="red_dot text-center">
                    1
                </span>
            </div> 
            <img width="30" :src="user" alt="">
        </div>
    </section>
</template>

<script setup>
    //icons
    const avatar = require('ASSETS/images/avatar')
    const bell = require('ASSETS/icons/bell')
    const user = require('ASSETS/icons/user')

    //vue
    import { computed } from 'vue';
    import { useStore} from 'vuex'

    //hooks
    const store = useStore()

    //computed
    const session = computed(() => (store.getters['getSession']))
</script>

<style lang="scss" scoped>
    #header {
        .red_dot{
            background: red;
            height: 20px;
            color: white;
            width: 20px;
            padding-top: 1px;
            padding-left: 2px;
            font-size: 12px;
            border-radius: 50%;
            position: absolute;
            bottom: 50%;
            right: -20%;
        }
    }
</style>