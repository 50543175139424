import sendRequest from "@/libs/sendRequest";

export  const reports = {
    namespaced: true,
    state: {
        report_data: null,
        customer_report: {
            total_items: 0,
            total_operations: 0,
            total_sales: 0,
            maps: [],
            ordenes: [],
            graph: [],
            graphOperations: []
        },
        product_report: {
            familias: [],
            categorias: [],
            productos: []
        }
    },
    getters: {
        getReportData: (state) => (state.report_data),
        getReportCustomer: (state) => {

            console.log('customer_report => ', state.customer_report)
            return state.customer_report
        },
        getReportLocations: (state) => {

            console.log('locations_report => ', state.customer_report)
            return state.customer_report
        },
        getReportNeigborhood: (state) => {

            console.log('neigborhood_report => ', state.neigborhood_report)
            return state.customer_report
        },
        getReportProducts: (state) => {
            console.log('products_report => ', state.product_report)
            return state.product_report
        }
    },
    mutations: {

        setCustomerReport: (state, payload) => {
            let maps = []
            let graph = [['Cliente', 'Venta'],]
            let graphOperations = [['Cliente', 'Operaciones'],]

            console.log('payload.ordenes => ', payload.ordenes)
            let a = 0
            for(let i in payload.ordenes) {
                const key = payload.ordenes[i]

                if(key.CoordenadaX != undefined && key.CoordenadaY != undefined) {

                    maps.push({
                        id: parseInt(i) + 1,
                        town: key.Colonia,
                        operations: key.NumeroDeOperaciones,
                        sales: key.TotalGeneral,
                        position: {
                            lat: key.CoordenadaY, lng: key.CoordenadaX
                        }
                    })

                    if(a <= 20){
                        graphOperations.push([
                            key.RazonSocial,
                            key.NumeroDeOperaciones
                        ])
                        graph.push([
                            key.RazonSocial,
                            key.TotalGeneral
                        ])
                        console.log(a)
                        a++
                    }
                }
            }

            state.customer_report.total_items = payload.total_items
            state.customer_report.total_operations = payload.total_operations
            state.customer_report.total_sales = payload.total_sales
            state.customer_report.graph = graph
            state.customer_report.graphOperations = graphOperations
            state.customer_report.ordenes = payload.ordenes ? payload.ordenes.map((item) => { return {...item, actions: '-'} }) : []
        },

        setNeigborhoodReport: (state, payload) => {
            let maps = []
            let graph = [['Cliente', 'Venta'],]
            let graphOperations = [['Cliente', 'Operaciones'],]

            let a = 0
            for(let i in payload.ordenes) {
                const key = payload.ordenes[i]

                if(key.CoordenadaX != undefined && key.CoordenadaY != undefined) {

                    maps.push({
                        id: parseInt(i) + 1,
                        name: key.Colonia,
                        operations: key.NumeroDeOperaciones,
                        sales: key.TotalGeneral,
                        position: {
                            lat: key.CoordenadaY, lng: key.CoordenadaX
                        }
                    })

                    if(a <= 20){
                        graphOperations.push([
                            key.Colonia,
                            key.NumeroDeOperaciones
                        ])
                        graph.push([
                            key.Colonia,
                            key.TotalGeneral
                        ])
                        a++
                    }
                }
            }

            state.customer_report.total_items = payload.total_items
            state.customer_report.total_operations = payload.total_operations
            state.customer_report.total_sales = payload.total_sales
            state.customer_report.maps = maps
            state.customer_report.graph = graph
            state.customer_report.graphOperations = graphOperations
            state.customer_report.ordenes = payload.ordenes ? payload.ordenes.map((item) => { return {...item, actions: '-'} }) : []
        },

        setLocationReport: (state, payload) => {
            let graph = [['Cliente', 'Venta'],]
            let graphOperations = [['Cliente', 'Operaciones'],]
            payload.ordenes = payload.ordenes
            for(let i in payload.ordenes?.length > 0 ? payload.ordenes?.slice(0,20) : []) {
                const key = payload.ordenes[i]
                    
                graphOperations.push([
                    key.Localidad,
                    key.NumeroDeOperaciones
                ])

                graph.push([
                    key.Localidad,
                    key.TotalGeneral
                ])
            }

            state.customer_report.total_items = payload.total_items
            state.customer_report.total_operations = payload.total_operations
            state.customer_report.total_sales = payload.total_sales
            state.customer_report.graph = graph
            state.customer_report.graphOperations = graphOperations
            state.customer_report.ordenes = payload.ordenes ? payload.ordenes.map((item) => { return {...item, actions: '-'} }) : []
        },

        setProductReport: (state, payload) => {
            console.log('PAYLOAD:', payload)
            state.product_report.prod_mayor = payload.prod_mayor
            state.product_report.prod_menor = payload.prod_menor
            state.product_report.prod_name_mayor = payload.prod_name_mayor
            state.product_report.prod_name_menor = payload.prod_name_menor
            state.product_report.fam_mayor = payload.fam_mayor
            state.product_report.fam_menor = payload.fam_menor
            state.product_report.fam_name_mayor = payload.fam_name_mayor
            state.product_report.fam_name_menor = payload.fam_name_menor,
            state.product_report.cat_mayor = payload.cat_mayor
            state.product_report.cat_menor = payload.cat_menor
            state.product_report.cat_name_mayor = payload.cat_name_mayor
            state.product_report.cat_name_menor = payload.cat_name_menor
            state.product_report.familias = payload.familias ?? []
            state.product_report.categorias = payload.categorias ?? []
            state.product_report.productos = payload.productos ?? []
        },

        setReportData: async(state, payload) => {
            let buf = new Uint8Array(payload).buffer
            let blob = new Blob([buf], { type: 'application/vnd.ms-excel;charset=utf-8' })
            const fr = new FileReader()
            fr.readAsDataURL(blob)

            fr.addEventListener('loadend', () => {
                console.log(fr.result)
                state.report_data = fr.result
            })
        }
    },
    actions: {

        customerReport: async({ commit, rootGetters }, payload) => {
            const  custom_headers = rootGetters.getHeaders
            const response = await sendRequest('administrator/reports/clients', 'GET', null, null, payload, custom_headers)


            if(response.success && response.data) {
                commit('setCustomerReport', response.data)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },

        neigborhoodReport: async({ commit, rootGetters }, payload) => {
            const  custom_headers = rootGetters.getHeaders
            const response = await sendRequest('administrator/reports/neigborhood', 'GET', null, null, payload, custom_headers)

            console.log('response:', response)
            if(response.success && response.data) {
                commit('setNeigborhoodReport', response.data)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },

        locationsReport: async({ commit, rootGetters }, payload) => {
            const  custom_headers = rootGetters.getHeaders
            const response = await sendRequest('administrator/reports/locations', 'GET', null, null, payload, custom_headers)


            if(response.success && response.data) {
                console.log('resport location => ', response.data)
                commit('setLocationReport', response.data)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },

        productReport: async ({ commit, dispatch, rootGetters}, dates) => {
            const  custom_headers = rootGetters.getHeaders
            const response = await sendRequest('administrator/reports/products', 'GET', null, null, dates, custom_headers)

            if(response.success && response.data) {
                commit('setProductReport', response.data)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },

        salesReport: async ({ commit, dispatch, rootGetters}, dates) => {
            const  custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('administrator/operations/select', 'GET', null, null, dates, custom_headers)

            if(response.success && response.data) {
                commit('setReportData', response.data.ordenes.data)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        }
    }

}